import request from "../utils/request";

export function get_sales_stat(data: JSON){
    return request({
        url: "/getSalesStat",
        method: "post",
        data: data
    });
}

export function get_channel_list(data: JSON){
    return request({
        url: "/getChannelList",
        method: "post",
        data: data
    });
}
